import React, {useEffect, useState, useRef, useCallback} from 'react';
import { Link as ReactRouterLink } from "react-router-dom";
import { Box, Fade, Stack, Typography, Button, Grid, List, ListItem, ListItemIcon, Container } from '@mui/material';
import { CheckCircle}  from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import MetaTags from '../library/metaTags';
import LoadingIcon from '../library/loadingIcon';
import PublicFooter from '../nav/footerPublic';
import { customFade } from '../../services/guiService';
// cms
import { client } from './prismic'
import { PrismicProvider, PrismicToolbar } from '@prismicio/react'
import { useSinglePrismicDocument } from '@prismicio/react';
import { linkResolver } from './slices/slices';
// images
import mintMap from '../../images/features/mint-map.jpg';
import locationOptions from '../../images/features/location-options.png';
import calendarSync from '../../images/features/calendar-sync.png';
import messaging from '../../images/features/messaging-reminders.png';
import groupPoll from '../../images/features/group-poll.png';
import revise from '../../images/features/easy-revise.png';
import bookingLinks from '../../images/features/ongoing-bookings.png';

const StyledList = styled(List)({
  '& .MuiListItem-root': {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 20,
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 0
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginLeft: 0,
    marginRight: 10,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 32,
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 0
  },
});

const Features = (props) => {  
  
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false]);
  const [loadingState, setLoadingState] = useState('loading');
  const [document] = useSinglePrismicDocument('features');
  const [width1, setWidth1] = useState(0);
  const [height1, setHeight1] = useState(0);
  const [width2, setWidth2] = useState(0);
  const [height2, setHeight2] = useState(0);
  const [width3, setWidth3] = useState(0);
  const [height3, setHeight3] = useState(0);
  const [width4, setWidth4] = useState(0);
  const [height4, setHeight4] = useState(0);
  const [width5, setWidth5] = useState(0);
  const [height5, setHeight5] = useState(0);
  const [width6, setWidth6] = useState(0);
  const [height6, setHeight6] = useState(0);
  const [render, setRender] = useState(false);

  const locationRef = useRef();
  const calendarRef = useRef();
  const messagingRef = useRef();
  const groupRef = useRef();
  const reviseRef = useRef();
  const bookingRef = useRef();

  // Fade and slide animations
  const fade = useCallback( (direction, redirect, includeSlide) => { customFade(null, fadeTime.length, setFadeTime, null, direction, redirect, null, includeSlide, props.appRoot.setFadeFooter) },[props.appRoot.setFadeFooter, fadeTime.length]);

  // determine the height and width of the responsive images
  const resizeImg = () => {
    if (locationRef.current) {
      const boxWidth = locationRef.current.getBoundingClientRect().width;
      const width = boxWidth > 550 ? 550 : boxWidth;
      const height = boxWidth > 550 ? 430 :430*width/550
      setWidth1(Math.floor(width));
      setHeight1(Math.floor(height))
    } ;
    if (calendarRef.current) {
      const boxWidth = calendarRef.current.getBoundingClientRect().width;
      const width = boxWidth > 550 ? 550 : boxWidth;
      const height = boxWidth > 550 ? 430 :430*width/550
      setWidth2(Math.floor(width));
      setHeight2(Math.floor(height))
    } ;
    if (messagingRef.current) {
      const boxWidth = messagingRef.current.getBoundingClientRect().width;
      const width = boxWidth > 550 ? 550 : boxWidth;
      const height = boxWidth > 550 ? 430 :430*width/550
      setWidth3(Math.floor(width));
      setHeight3(Math.floor(height))
    }
    if (groupRef.current) {
      const boxWidth = groupRef.current.getBoundingClientRect().width;
      const width = boxWidth > 550 ? 550 : boxWidth;
      const height = boxWidth > 550 ? 430 :430*width/550
      setWidth4(Math.floor(width));
      setHeight4(Math.floor(height))
    }
    if (reviseRef.current) {
      const boxWidth = reviseRef.current.getBoundingClientRect().width;
      const width = boxWidth > 550 ? 550 : boxWidth;
      const height = boxWidth > 550 ? 430 :430*width/550
      setWidth5(Math.floor(width));
      setHeight5(Math.floor(height))
    }
    if (bookingRef.current) {
      const boxWidth = bookingRef.current.getBoundingClientRect().width;
      const width = boxWidth > 550 ? 550 : boxWidth;
      const height = boxWidth > 550 ? 430 :430*width/550
      setWidth6(Math.floor(width));
      setHeight6(Math.floor(height))
    }
  }

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    window.addEventListener("resize", resizeImg);
    return () => window.removeEventListener("resize", resizeImg);
  }, []);

  useEffect( () => {
    if (loadingState === 'loading' && document && document.data) {
      setLoadingState('preload');
    } else if (loadingState === 'preload') {
      setLoadingState('complete');
      resizeImg();
      fade('in');
    }
  }, [loadingState, document, fade]);
  
  useEffect( () => {
    const goRender = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      window.prerenderReady = true;
    }
    if (!window.prerenderReady && fadeTime[fadeTime.length-1] === true && !render) {
      setRender(true);
      goRender();
    } 
  },[fadeTime, render]);

  if (loadingState === 'loading') {
    return (
      <LoadingIcon />
    )
  } else {
    return (
      <Box>
        <MetaTags content={document} />

        {/* Hero Section */}
        <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Box sx={{width: '100%', backgroundImage: `url(${mintMap})`, backgroundRepeat: 'repeat', height: {xs: '375px', sm: '350px'}, boxShadow: `0px 3px 12px 0px #eeeeee`}}>
            <Box sx={{width: '100%', height: '100%', backgroundColor: 'rgb(231,241,244,0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}  align="center">
              <Stack alignItems="center" spacing={1} pl={2} pr={2}>
                <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Typography variant="h3" component="h1" sx={{fontWeight: 'bold', textShadow: `2px 2px 10px #f6f6f6`}}>Meetings just got easier</Typography>
                </Fade>
                <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Box typography="h6" component="h2" pt={2} sx={{lineHeight: '2.0em', fontWeight: 'bold'}}>
                    Simple, powerful features to help co-workers, clients and friends get together
                  </Box>
                </Fade>
                <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Box pt={2}><Button variant="contained" color="primary" size="large" component={ReactRouterLink} to="/invite">Invite Someone</Button></Box>
                </Fade> 
              </Stack>
            </Box>
          </Box>
        </Fade>

        <Box mt={{xs: 4, sm: 8}}>&nbsp;</Box>

        {/* Location features */}
        <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Container maxWidth="lg" >
            <Grid container justifyContent="center" alignItems="center" spacing={{xs: 0, sm: 3, md: 6}} pl={2} pr={2}>
              <Grid item xs={12} md={6} sx={{textAlign: {xs: 'center', md: 'left'}}} pb={{xs: 2, sm: 0}}>
                <Typography variant="h4" component="h4" color="headers.main" sx={{fontWeight: 'bold'}}>Location. Location. Location.</Typography>
                <Typography variant="h6" component="h3" mt={2} mb={2} sx={{fontWeight: 'bold'}}>FINALLY, a scheduling tool that handles more than time, letting invitees also choose the place.</Typography>
                <Box typography="body1" align="center">
                  <StyledList sx={{maxWidth: '460px'}}>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Give invitees in-person and virtual options</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Search for coffee or food by area or between locations</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Include your office or home as a meetup spot</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Save a list of favorite locations for meetings</ListItem>
                  </StyledList>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack sx={{width: '100%'}} alignItems={{xs: 'center', md: 'flex-end'}} ref={locationRef}>
                  <img alt="Let your invitee choose the location or place to meet" src={locationOptions} width={width1} height={height1} border="0" />  
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Fade>

        <Box mt={{xs: 4, sm: 6}}>&nbsp;</Box>

        {/* Calendar features */}
        <Fade in={fadeTime[5]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Box sx={{width: '100%', backgroundColor: '#f6f6f6'}} pt={8} pb={7}>
            <Container maxWidth="lg">
              <Grid container justifyContent="center" alignItems="center" spacing={{xs: 0, sm: 3, md: 6}} pl={2} pr={2}>
                <Grid item xs={12} md={6}>
                  <Stack sx={{width: '100%'}} alignItems={{xs: 'center', md: 'flex-end'}} ref={calendarRef} pb={{xs: 4, sm: 0}}>
                    <img alt="Sync your meetings with your Google Calendar or Microsoft Outlook/Teams" src={calendarSync} width={width2} height={height2} border="0" />  
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} sx={{textAlign: {xs: 'center', md: 'left'}}}>
                  <Typography variant="h4" component="h4" color="headers.main" sx={{fontWeight: 'bold'}}>Let it all sync in</Typography>
                  <Typography variant="h6" component="h3" mt={2} mb={2} sx={{fontWeight: 'bold'}}>Effortlessly sync your meetups with your Microsoft or Google calendar and ensure you never miss a meeting.</Typography>
                  <Box typography="body1" align="center">
                    <StyledList sx={{maxWidth: '460px'}}>
                      <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>View your calendars in Meetify for easy scheduling</ListItem>
                      <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Auto-add calendar placeholders for possible times</ListItem>
                      <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Auto-update your calendar on responses or cancels</ListItem>
                      <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Dynamically prevent overlaps with calendar events</ListItem>
                    </StyledList>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Fade>

        <Box mt={{xs: 4, sm: 8}}>&nbsp;</Box>

        {/* Booking LInk features */}
        <Fade in={fadeTime[5]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Container maxWidth="lg">
            <Grid container justifyContent="center" alignItems="center" spacing={{xs: 0, sm: 3, md: 6}} pl={2} pr={2}>
              <Grid item xs={12} md={6} sx={{textAlign: {xs: 'center', md: 'left'}}} pb={{xs: 2, sm: 0}}>
                <Typography variant="h4" component="h3" color="headers.main" sx={{fontWeight: 'bold'}}>Book 'em, Danno!</Typography>
                <Typography variant="h6" component="h4" mt={2} mb={2} sx={{fontWeight: 'bold'}}>Create a single booking link based on a custom schedule for effortless appointments.</Typography>
                <Box typography="body1" align="center">
                  <StyledList sx={{maxWidth: '460px'}}>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Automatically exclude holidays from your availability</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Easily add exceptions for your vacation or a special event</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Build unlimited schedules and meeting types</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Dynamically build an email signature or web button</ListItem>
                  </StyledList>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack sx={{width: '100%'}} alignItems={{xs: 'center', md: 'flex-end'}} ref={bookingRef}>
                  <img alt="Create appointment links or booking links" src={bookingLinks} width={width6} height={height6} border="0" />  
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Fade>







        <Box mt={{xs: 4, sm: 8}}>&nbsp;</Box>


        {/* Group features */}
        <Fade in={fadeTime[5]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Box sx={{width: '100%', backgroundColor: '#edf5f6'}} pt={8} pb={7}>
            <Container maxWidth="lg">
              <Grid container justifyContent="center" alignItems="center" spacing={{xs: 0, sm: 3, md: 6}} pl={2} pr={2}>
                <Grid item xs={12} md={6}>
                  <Stack sx={{width: '100%'}} alignItems={{xs: 'center', md: 'flex-end'}} ref={groupRef} pb={{xs: 4, sm: 0}}>
                    <img alt="Group availability meeting scheduling or polls" src={groupPoll} width={width4} height={height4} border="0" />  
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} sx={{textAlign: {xs: 'center', md: 'left'}}}>
                  <Typography variant="h4" component="h4" color="headers.main" sx={{fontWeight: 'bold'}}>Make it a party</Typography>
                  <Typography variant="h6" component="h3" mt={2} mb={2} sx={{fontWeight: 'bold'}}>Easily coordinate a group get-together and find the best time and location for everyone.</Typography>
                  <Box typography="body1" align="center">
                    <StyledList sx={{maxWidth: '460px'}}>
                      <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Poll participants on their interest and availability</ListItem>
                      <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>View a visual breakdown of the top choices</ListItem>
                      <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Finalize the time and place and notify everyone</ListItem>
                      <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Revise, add options, or reschedule with ease</ListItem>
                    </StyledList>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Fade>

        <Box mt={{xs: 4, sm: 8}}>&nbsp;</Box>


                {/* Messaging features */}
                <Fade in={fadeTime[5]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Container maxWidth="lg">
            <Grid container justifyContent="center" alignItems="center" spacing={{xs: 0, sm: 3, md: 6}} pl={2} pr={2}>
              <Grid item xs={12} md={6} sx={{textAlign: {xs: 'center', md: 'left'}}} pb={{xs: 2, sm: 0}}>
                <Typography variant="h4" component="h3" color="headers.main" sx={{fontWeight: 'bold'}}>Get the word out</Typography>
                <Typography variant="h6" component="h4" mt={2} mb={2} sx={{fontWeight: 'bold'}}>Integrated invites, messaging, reminders, and notifications ensure communication is clear.</Typography>
                <Box typography="body1" align="center">
                  <StyledList sx={{maxWidth: '460px'}}>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Send invites yourself or let Meetify handle it</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Use integrated text messaging for quick delivery</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Save frequent contacts for easy repeat meetups</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Get reminders even in the free version</ListItem>
                  </StyledList>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack sx={{width: '100%'}} alignItems={{xs: 'center', md: 'flex-end'}} ref={messagingRef}>
                  <img alt="Free reminders and easy email and text messaging" src={messaging} width={width3} height={height3} border="0" />  
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Fade>

        <Box mt={{xs: 4, sm: 8}}>&nbsp;</Box>


        {/* Revise features */}
        <Fade in={fadeTime[5]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Box sx={{width: '100%', backgroundColor: '#f6f6f6'}} pt={8} pb={7}>
            <Container maxWidth="lg">
              <Grid container justifyContent="center" alignItems="center" spacing={{xs: 0, sm: 3, md: 6}} pl={2} pr={2}>
                <Grid item xs={12} md={6}>
                  <Stack sx={{width: '100%'}} alignItems={{xs: 'center', md: 'flex-end'}} ref={reviseRef} pb={{xs: 4, sm: 0}}>
                  <img alt="Respond, revise, reschedule, delay with ease" src={revise} width={width5} height={height5} border="0" />  
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} sx={{textAlign: {xs: 'center', md: 'left'}}}>
                <Typography variant="h4" component="h4" color="headers.main" sx={{fontWeight: 'bold'}}>Built for the real world</Typography>
                <Typography variant="h6" component="h3" mt={2} mb={2} sx={{fontWeight: 'bold'}}>Effortlessly manage delays, schedule changes, and the complexities of modern meetings.</Typography>
              <Box typography="body1" align="center">
                    <StyledList sx={{maxWidth: '460px'}}>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Delay the start time if running late</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Shift to an alternate location or virtual meeting</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Enable participants to suggest alternatives</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Streamline rescheduling when needed</ListItem>
                    </StyledList>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Fade>




        <Box mt={{xs: 8, sm: 10}}>&nbsp;</Box>


        <PublicFooter appRoot={props.appRoot} />
      </Box>
    );
  }
}

const Wrapper = (props) => {
  return (
    <PrismicProvider client={client} linkResolver={linkResolver}>
      <Features {...props} />
      {process.env.REACT_APP_ENV !== 'production' ? <PrismicToolbar repositoryName="meetify" /> : null}
    </PrismicProvider>
  )
}

export default Wrapper;