import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Typography, Container, Link, Box, Fade, Stack, Button, Grid } from '@mui/material';
import EmployeeGrid from './employees';
import ArticlesLarge from './articlesLarge';
import { SliceZone, PrismicRichText } from '@prismicio/react';

const getColor = (color)=>{
  if (color.indexOf('Mint') !== -1) return 'secondary.main';
  if (color.indexOf('Teal') !== -1) return 'primary.main';
  return 'headers.main';
}


const linkResolver = (doc) => {
  let relativePath;
  if ( ['coming_soon', 'home_page'].includes(doc.type) ) relativePath = `${process.env.REACT_APP_WEB_URL}`;
  if ( ['privacy', 'tos','features','pricing', 'about', 'support', 'resources','404', 'unsubscribe','cookie','style'].includes(doc.type) ) relativePath = `${process.env.REACT_APP_WEB_URL}/${doc.uid ? doc.uid : doc.type}`
  if (doc.type === 'article') {
    relativePath = `${process.env.REACT_APP_WEB_URL}/resources/${doc.tags.length ? doc.tags[0].toLowerCase() + '/' : ''}${doc.uid}`
  }
  if (doc.type === 'landing_page') {
    relativePath = `${process.env.REACT_APP_WEB_URL}/${doc.uid}`
  }
  if (doc.type === 'resource_category') {
    relativePath = `${process.env.REACT_APP_WEB_URL}/resources/${doc.uid}`
  }
  if (doc.type === "anchor_links") {
    relativePath = `#${doc.uid}`
  }
  if (!relativePath) return '/';
  return relativePath;
}


const TextArea = ({slice}) => {
  return (
    <section>
      <PrismicRichText field={slice.primary.text}
      components={{
        heading1: ({ children }) => <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
        heading2: ({ children }) => <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
        heading3: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
        heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
        heading5: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
        heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
        paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
        preformatted: ({ node, key }) => <pre key={key}>{node.text}</pre>,
        strong: ({ children, key }) => <strong key={key}>{children}</strong>,
        em: ({ children, key }) => <em key={key}>{children}</em>,
        listItem: ({ children, key }) => <li key={key}>{children}</li>,
        list: ({ children, key }) => <Box typography="body1"><ul key={key}>{children}</ul></Box>,
        oListItem: ({ children, key }) => <li key={key}>{children}</li>,
        oList: ({ children, key }) => <Box typography="body1"><ol key={key}>{children}</ol></Box>,
      }}
      linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
    </section>
  )
}

const ResponsiveImage = ({field, height, width}) => {
  return (
    <picture>
      {field.mobile ? <source media="(max-width: 400px)" srcSet={field.mobile.url} /> : null}
      {field.tablet ? <source media="(max-width: 900px)" srcSet={field.tablet.url} /> : null}
      <source media="(max-width: 1600px)" srcSet={field.url} />
      <source srcSet={field.url} />
      {height && width ?
        <img src={field.url} alt={field.alt ? field.alt : ''} height={height} width={width} style={{borderRadius: '8px',boxShadow: '3px 3px 5px rgba(0,0,0,0.15)'}} border="0" />
      : 
        <img src={field.url} alt={field.alt ? field.alt : ''} width="100%" style={{borderRadius: '8px',boxShadow: '3px 3px 5px rgba(0,0,0,0.15)'}} border="0" />
      }
    </picture>
  )
}

const ResponsiveImageClean = ({field, height, width, className}) => {
  return (
    <picture>
      {field.mobile ? <source media="(max-width: 400px)" srcSet={field.mobile.url} /> : null}
      {field.tablet ? <source media="(max-width: 900px)" srcSet={field.tablet.url} /> : null}
      <source media="(max-width: 1600px)" srcSet={field.url} />
      <source srcSet={field.url} />
      {height && width ?
        <img src={field.url} alt={field.alt ? field.alt : ''} height={height} width={width} border="0" className={className} />
      : 
        <img src={field.url} alt={field.alt ? field.alt : ''} width="100%" border="0" />
      }
    </picture>
  )
}


const Header2Column = ({slice}) => {
  const ImgSlice = () => {
    return (
      <Grid item sm={12} md={6} sx={{pl: {sm: 0, md: 6}, pt: {sm: 4, md: 4}, display: slice.primary.image ? 'block' : 'none'}}>
        {slice.primary.image ? <ResponsiveImage field={slice.primary.image} /> : null}
      </Grid>
    )
  }
  return (
    <Container maxWidth="lg">
      <Grid container>
        {slice.primary.image_align === 'left' ? <ImgSlice /> : null}
        <Grid item sm={12} md={6}>
          <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}} mb={2}>{slice.primary.title[0].text}</Typography>
          <PrismicRichText field={slice.primary.text_area} 
            components={{
              heading1: ({ children }) => <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
              heading2: ({ children }) => <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
              heading3: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
              heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
              heading5: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
              heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
              paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
              preformatted: ({ node, key }) => <pre key={key}>{node.text}</pre>,
              strong: ({ children, key }) => <strong key={key}>{children}</strong>,
              em: ({ children, key }) => <em key={key}>{children}</em>,
              listItem: ({ children, key }) => <li key={key}>{children}</li>,
              list: ({ children, key }) => <Box typography="body1"><ul key={key}>{children}</ul></Box>,
              oListItem: ({ children, key }) => <li key={key}>{children}</li>,
              oList: ({ children, key }) => <Box typography="body1"><ol key={key}>{children}</ol></Box>,
            }}
            linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
          </Grid>
        {slice.primary.image_align === 'right' ? <ImgSlice /> : null}
      </Grid>
    </Container>
  )
}

const Column2Quote = ({slice}) => {

  const Quote = () => {
    return (
      <Grid item sm={12} md={6} sx={{pt: {sm: 3, md: 3}, pl: {sm: 0, md: 6}, textAlign: slice.primary.quote_align}}>
        { slice.primary.quote_header.length ? <PrismicRichText field={slice.primary.quote_header} 
            components={{
              heading1: ({ children }) => <Typography variant="h3" component="h1" mb={1}>{children}</Typography>,
              heading2: ({ children }) => <Typography variant="h4" component="h2" mb={1}>{children}</Typography>,
              heading3: ({ children }) => <Typography variant="h5" component="h3" mb={1}>{children}</Typography>,
              heading4: ({ children }) => <Typography variant="h5" component="h3" mb={1} sx={{color:'headers.main'}}>{children}</Typography>,
              heading5: ({ children }) => <Typography variant="h6" component="h4" mb={1}>{children}</Typography>,
              heading6: ({ children }) => <Typography variant="h6" component="h4" mb={1} sx={{color:'headers.main'}}>{children}</Typography>,
              paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
              strong: ({ children, key }) => <strong key={key}>{children}</strong>}}
              linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
              : null}
        <PrismicRichText field={slice.primary.quote_text} 
            components={{
              heading1: ({ children }) => <Typography variant="h3" component="h1">{children}</Typography>,
              heading2: ({ children }) => <Typography variant="h4" component="h2">{children}</Typography>,
              heading3: ({ children }) => <Typography variant="h5" component="h3">{children}</Typography>,
              heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{color:'headers.main'}}>{children}</Typography>,
              heading5: ({ children }) => <Typography variant="h6" component="h4">{children}</Typography>,
              heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{color:'headers.main'}}>{children}</Typography>,
              paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
              strong: ({ children, key }) => <strong key={key}>{children}</strong>}}
              linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
              { slice.primary.quote_footer.length ? <PrismicRichText field={slice.primary.quote_footer} 
            components={{
              heading1: ({ children }) => <Typography variant="h3" component="h1" mt={1}>{children}</Typography>,
              heading2: ({ children }) => <Typography variant="h4" component="h2" mt={1}>{children}</Typography>,
              heading3: ({ children }) => <Typography variant="h5" component="h3" mt={1}>{children}</Typography>,
              heading4: ({ children }) => <Typography variant="h5" component="h3" mt={1} sx={{color:'headers.main'}}>{children}</Typography>,
              heading5: ({ children }) => <Typography variant="h6" component="h4" mt={1}>{children}</Typography>,
              heading6: ({ children }) => <Typography variant="h6" component="h4" mt={1} sx={{color:'headers.main'}}>{children}</Typography>,
              paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
              strong: ({ children, key }) => <strong key={key}>{children}</strong>}}
              linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
              : null}
      </Grid>
    )
  }
  return (
    <Box sx={{boxShadow: '0px 2px 5px #eeeeee'}}>
    <Box pt={4} pb={4} sx={{backgroundColor: '#e2f1e8', boxShadow: 'inset 0px 0px 50px #b9dec8'}}>
      <Container maxWidth="lg">
      <Grid container>
      {slice.primary.quote_side === 'left' ? <Quote /> : null}
        <Grid item sm={12} md={6} sx={{textAlign: {sm: 'center', md: 'left'}}}>
          <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mb={2}>{slice.primary.title[0].text}</Typography>
          <PrismicRichText field={slice.primary.text_area} 
          components={{
            heading1: ({ children }) => <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
            heading2: ({ children }) => <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
            heading3: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
            heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
            heading5: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
            heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
            paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
            preformatted: ({ node, key }) => <pre key={key}>{node.text}</pre>,
            strong: ({ children, key }) => <strong key={key}>{children}</strong>,
            em: ({ children, key }) => <em key={key}>{children}</em>,
            listItem: ({ children, key }) => <li key={key}>{children}</li>,
            list: ({ children, key }) => <Box typography="body1"><ul key={key}>{children}</ul></Box>,
            oListItem: ({ children, key }) => <li key={key}>{children}</li>,
            oList: ({ children, key }) => <Box typography="body1"><ol key={key}>{children}</ol></Box>,
          }}
          linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
          </Grid>
      {slice.primary.quote_side === 'right' ? <Quote /> : null}
      </Grid>
      </Container>
    </Box>
    </Box>
  )
}

const BannerVertical = ({slice}) => {

  return (
    <Box>
      {slice.primary && slice.primary.banner_image && slice.primary.banner_image.url ?
        slice.primary.link && slice.primary.link.url ?
          <Link component={ReactRouterLink} to={slice.primary.link.url} aria-label={slice.primary.banner_image.alt ? slice.primary.banner_image.alt : ''}><img src={slice.primary.banner_image.url} alt={slice.primary.banner_image.alt ? slice.primary.banner_image.alt : ''} width="300" height="600" border="0" style={{borderRadius: '8px',boxShadow: '3px 3px 5px rgba(0,0,0,0.15)'}} /></Link>
          : 
          <img src={slice.primary.banner_image.url} alt={slice.primary.banner_image.alt ? slice.primary.banner_image.alt : ''} width="300" height="600" border="0"  style={{borderRadius: '8px',boxShadow: '3px 3px 5px rgba(0,0,0,0.15)'}}  />
      : null}
    </Box>
  )
}

const Employees = ({slice}) => {
  return (
    <Container align="center">
      <Box maxWidth="md" sx={{textAlign: 'center'}} mb={4}>
        <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mb={2}>{slice.primary.section_title[0].text}</Typography>
        <PrismicRichText field={slice.primary.section_text}
        components={{
          heading1: ({ children }) => <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading2: ({ children }) => <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading3: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
          heading5: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
          paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
          preformatted: ({ node, key }) => <pre key={key}>{node.text}</pre>,
          strong: ({ children, key }) => <strong key={key}>{children}</strong>,
          em: ({ children, key }) => <em key={key}>{children}</em>,
          listItem: ({ children, key }) => <li key={key}>{children}</li>,
          list: ({ children, key }) => <Box typography="body1"><ul key={key}>{children}</ul></Box>,
          oListItem: ({ children, key }) => <li key={key}>{children}</li>,
          oList: ({ children, key }) => <Box typography="body1"><ol key={key}>{children}</ol></Box>,
        }}
        linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
        </Box>
      <EmployeeGrid /> 
    </Container>
  )
}

const ArticleImageFull = ({slice}) => {
  return (
    <div align="center">
    <Box mt={4} mb={4} sx={{width: {xs: '100%', sm: slice.primary.image_width.replace(' of the copy width','')}}}>
      {slice.primary.article_image ?
      slice.primary.link && slice.primary.link.url ?
          <Link component={ReactRouterLink} to={slice.primary.link.url}><ResponsiveImage field={slice.primary.article_image} /></Link>
          : 
      <ResponsiveImage field={slice.primary.article_image} />
      : null}
    </Box>
    </div>
  )
}

const ArticleImageHalf = ({slice}) => {
  const ShowImage = () => {
    return (
      <Box sx={{pt: {xs: 2, md: 0}, pb: {xs: 3, md: 0}, textAlign: 'center'}}>
        {slice.primary.article_image ? <ResponsiveImage field={slice.primary.article_image} /> : null}
        {slice.primary.image_description && slice.primary.image_description.length ?
        <Typography variant="body1" sx={{fontWeight: 'bold'}}>{slice.primary.image_description[0].text}</Typography>
        : null}
      </Box>
    )
  }
  return (
    <Grid container alignItems="center">
      {slice.primary.align_image === 'left' ?
      <Grid item xs={12} md={6} sx={{pr: {xs: 0, md: 6}}}><ShowImage /></Grid>
      : null}
      <Grid item sm={12} md={6} sx={{textAlign: 'left'}}>
        <PrismicRichText field={slice.primary.text_area} 
        components={{
          heading1: ({ children }) => <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading2: ({ children }) => <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading3: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
          heading5: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
          paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
          preformatted: ({ node, key }) => <pre key={key}>{node.text}</pre>,
          strong: ({ children, key }) => <strong key={key}>{children}</strong>,
          em: ({ children, key }) => <em key={key}>{children}</em>,
          listItem: ({ children, key }) => <li key={key}>{children}</li>,
          list: ({ children, key }) => <Box typography="body1"><ul key={key}>{children}</ul></Box>,
          oListItem: ({ children, key }) => <li key={key}>{children}</li>,
          oList: ({ children, key }) => <Box typography="body1"><ol key={key}>{children}</ol></Box>,
        }}
        linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
        </Grid>
      {slice.primary.align_image === 'right' ?
      <Grid item xs={12} md={6} sx={{pl: {xs: 0, md: 6}}}><ShowImage /></Grid>
      : null}
    </Grid>
    )
}

const ArticleQuote = ({slice}) => {

  const Quote = () => {
    return (
      <Box sx={{textAlign: slice.primary.quote_align}}>
        { slice.primary.quote_head.length ? <PrismicRichText field={slice.primary.quote_head} 
            components={{
              heading1: ({ children }) => <Typography variant="h3" component="h1" mb={1} sx={{color: getColor(slice.primary.quote_head_color)}}>{children}</Typography>,
              heading2: ({ children }) => <Typography variant="h4" component="h2" mb={1} sx={{color: getColor(slice.primary.quote_head_color)}}>{children}</Typography>,
              heading3: ({ children }) => <Typography variant="h5" component="h3" mb={1} sx={{color: getColor(slice.primary.quote_head_color)}}>{children}</Typography>,
              heading4: ({ children }) => <Typography variant="h5" component="h3" mb={1} sx={{color: getColor(slice.primary.quote_head_color)}}>{children}</Typography>,
              heading5: ({ children }) => <Typography variant="h6" component="h4" mb={1} sx={{color: getColor(slice.primary.quote_head_color)}}>{children}</Typography>,
              heading6: ({ children }) => <Typography variant="h6" component="h4" mb={1} sx={{color: getColor(slice.primary.quote_head_color)}}>{children}</Typography>,
              paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
              strong: ({ children, key }) => <strong key={key}>{children}</strong>}}
              linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
              : null}
        <PrismicRichText field={slice.primary.quote_text} 
            components={{
              heading1: ({ children }) => <Typography variant="h3" component="h1" sx={{color: getColor(slice.primary.quote_text_color)}}>{children}</Typography>,
              heading2: ({ children }) => <Typography variant="h4" component="h2" sx={{color: getColor(slice.primary.quote_text_color)}}>{children}</Typography>,
              heading3: ({ children }) => <Typography variant="h5" component="h3" sx={{color: getColor(slice.primary.quote_text_color)}}>{children}</Typography>,
              heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{color: getColor(slice.primary.quote_text_color)}}>{children}</Typography>,
              heading5: ({ children }) => <Typography variant="h6" component="h4" sx={{color: getColor(slice.primary.quote_text_color)}}>{children}</Typography>,
              heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{color: getColor(slice.primary.quote_text_color)}}>{children}</Typography>,
              paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
              strong: ({ children, key }) => <strong key={key}>{children}</strong>}}
              linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
              { slice.primary.quote_foot.length ? <PrismicRichText field={slice.primary.quote_foot} 
            components={{
              heading1: ({ children }) => <Typography variant="h3" component="h1" mt={1} sx={{color: getColor(slice.primary.quote_foot_color)}}>{children}</Typography>,
              heading2: ({ children }) => <Typography variant="h4" component="h2" mt={1} sx={{color: getColor(slice.primary.quote_foot_color)}}>{children}</Typography>,
              heading3: ({ children }) => <Typography variant="h5" component="h3" mt={1} sx={{color: getColor(slice.primary.quote_foot_color)}}>{children}</Typography>,
              heading4: ({ children }) => <Typography variant="h5" component="h3" mt={1} sx={{color: getColor(slice.primary.quote_foot_color)}}>{children}</Typography>,
              heading5: ({ children }) => <Typography variant="h6" component="h4" mt={1} sx={{color: getColor(slice.primary.quote_foot_color)}}>{children}</Typography>,
              heading6: ({ children }) => <Typography variant="h6" component="h4" mt={1} sx={{color: getColor(slice.primary.quote_foot_color)}}>{children}</Typography>,
              paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
              strong: ({ children, key }) => <strong key={key}>{children}</strong>}}
              linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
              : null}
      </Box>
    )
  }
  if (slice.primary.quote_width.includes('Full width')) {
    return (
      <Box pr={6} pl={6} pt={1} pb={3} sx={{textAlign: 'center'}}>
        <Quote />
        {slice.primary.text_area ? <PrismicRichText field={slice.primary.text_area} components={{
        heading1: ({ children }) => <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
        heading2: ({ children }) => <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
        heading3: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
        heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
        heading5: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
        heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
        paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
        preformatted: ({ node, key }) => <pre key={key}>{node.text}</pre>,
        strong: ({ children, key }) => <strong key={key}>{children}</strong>,
        em: ({ children, key }) => <em key={key}>{children}</em>,
        listItem: ({ children, key }) => <li key={key}>{children}</li>,
        list: ({ children, key }) => <Box typography="body1"><ul key={key}>{children}</ul></Box>,
        oListItem: ({ children, key }) => <li key={key}>{children}</li>,
        oList: ({ children, key }) => <Box typography="body1"><ol key={key}>{children}</ol></Box>,
      }}
      linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} /> : null}
      </Box>
    )
  } else {
  return (
    <Grid container alignItems="center">
      {slice.primary.quote_width.includes('left') ?
      <Grid item xs={12} md={6} sx={{pt: {xs: 1, md: 0}, pr: 6, pl: {xs: 6, md: 0}, pb: {xs: 3, md: 0}, textAlign: 'center'}}><Quote /></Grid>
      : null}
      <Grid item sm={12} md={6} sx={{textAlign: 'left'}}>
        <PrismicRichText field={slice.primary.text_area}
        components={{
          heading1: ({ children }) => <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading2: ({ children }) => <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading3: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
          heading5: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
          paragraph: ({ children }) => <Typography variant="body1" mb={2} sx={{fontSize: '1.125em'}}>{children}</Typography>,
          preformatted: ({ node, key }) => <pre key={key}>{node.text}</pre>,
          strong: ({ children, key }) => <strong key={key}>{children}</strong>,
          em: ({ children, key }) => <em key={key}>{children}</em>,
          listItem: ({ children, key }) => <li key={key}>{children}</li>,
          list: ({ children, key }) => <Box typography="body1"><ul key={key}>{children}</ul></Box>,
          oListItem: ({ children, key }) => <li key={key}>{children}</li>,
          oList: ({ children, key }) => <Box typography="body1"><ol key={key}>{children}</ol></Box>,
        }}
        linkResolver={linkResolver} internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href.replace(process.env.REACT_APP_WEB_URL,'')} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} />
        </Grid>
      {slice.primary.quote_width.includes('right') ?
      <Grid item xs={12} md={6} sx={{pt: {xs: 1, md: 0}, pl: 6, pr: {xs: 6, md: 0}, pb: {xs: 3, md: 0}, textAlign: 'center'}}><Quote /></Grid>
      : null}
    </Grid>
    )
  }
}

const HeaderTint = ({slice}) => {
  const rgb = slice.primary.color === 'Go Blue' ? '19,46,83' : slice.primary.color === 'Tasty Teal' ? '0,115,119' : '168,212,186';
  const textColor1 = slice.primary.color !== 'Meetify Mint' ? 'primary.contrastText' : 'primary.dark';
  const textColor2 = slice.primary.color === 'Meetify Mint' ? 'primary' : slice.primary.color === 'Tasty Teal' ? 'primary.contrastText' : 'primary.contrastText';
  const shadowColor = slice.primary.color !== 'Meetify Mint' ? '#000000' : '#FFFFFF';
  return (
    <Box sx={{width: '100%', boxShadow: '0px 5px 5px #eeeeee', borderTop: 1, borderColor: 'white', backgroundImage: {xs: `url(${slice.primary.header_image.mobile.url})`, sm: `url(${slice.primary.header_image.tablet.url})`, md: `url(${slice.primary.header_image.url})`}, height: 400, backgroundSize: 'cover', backgroundPosition: 'center'}}>
      <Box sx={{width: '100%', height: 400, backgroundColor: `rgb(${rgb},.${slice.primary.transparency[0]})`}}>
        <Container maxWidth="lg" align={slice.primary.text_align === 'center' ? 'center' : slice.primary.text_align === 'right' ? 'flex-end' : 'flex-start'}>
          <Box sx={{display: 'flex', alignItems: 'center', height: '395px', width: '50%'}}>
          <Stack justifyContent="center" alignItems="center" sx={{width: '100%', textAlign: slice.primary.text_align === 'center' ? 'center' : slice.primary.text_align === 'right' ? 'flex-end' : 'flex-start'}}>
          <Typography variant="h2" mb={1} component="h1" sx={{fontWeight: 'bold', textShadow: `2px 2px 10px ${shadowColor}`}} color={textColor1}>{slice.primary.title[0].text}</Typography>
          <Typography variant="h6" mb={1} component="h2" sx={{fontWeight: 'bold', textShadow: `1px 1px 6px ${shadowColor}`}} color={textColor2}>{slice.primary.subheader[0].text}</Typography>
          {slice.primary.button_text ? <Button component={ReactRouterLink} to={slice.primary.button_link.url} size={slice.primary.button_size} color="secondary" variant="contained">{slice.primary.button_text}</Button> : null }
          </Stack>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

const LatestLarge = ({slice}) => {
  return (
    <Box>
      {/* <Box sx={{textAlign: `${slice.primary.text_align}`}} mb={4}>
        {slice.category ? 
        <Box><Typography variant="body2" color="textSecondary" mb={1}><Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/resources`} color="textSecondary">Resources</Link>&nbsp;&nbsp;/&nbsp;&nbsp;<Link component={ReactRouterLink} to={process.env.REACT_APP_WEB_URL + '/resources/' + slice.category} color="textSecondary">{slice.category.charAt(0).toUpperCase() + slice.category.slice(1)}</Link></Typography></Box>
        : 
        <Box><Typography variant="body2" color="textSecondary" mb={1}><Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/resources`} color="textSecondary">Resources</Link>&nbsp;&nbsp;/&nbsp;&nbsp;All</Typography></Box>
        }
        {slice.primary.section_title && slice.primary.section_title.length ? <Typography variant="h3" component="h3" sx={{fontWeight: 'bold'}}>{slice.primary.section_title[0].text}</Typography> : null}
        {slice.primary.text_area && slice.primary.text_area.length ? <Box mt={1}><PrismicRichText field={slice.primary.text_area} 
        components={{
          heading1: ({ children }) => <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading2: ({ children }) => <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading3: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
          heading5: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
          heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
          paragraph: ({ children }) => <Typography variant="body1" mb={2}>{children}</Typography>,
          preformatted: ({ node, key }) => <pre key={key}>{node.text}</pre>,
          strong: ({ children, key }) => <strong key={key}>{children}</strong>,
          em: ({ children, key }) => <em key={key}>{children}</em>,
          listItem: ({ children, key }) => <li key={key}>{children}</li>,
          list: ({ children, key }) => <Box typography="body1"><ul key={key}>{children}</ul></Box>,
          oListItem: ({ children, key }) => <li key={key}>{children}</li>,
          oList: ({ children, key }) => <Box typography="body1"><ol key={key}>{children}</ol></Box>,
        }}
        internalLinkComponent={(props) => <Link {...props} component={ReactRouterLink} to={props.href} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.href.includes(process.env.REACT_APP_WEB_URL) ? props.target : '_blank'}>{props.children}</Link>} /></Box> : null}
      </Box> */}
      
      <ArticlesLarge category={slice.category} primary={slice.primary} />
    </Box>
  )
}

const Anchor = ({slice}) => {
  return (
    <a id={slice.primary.anchor.uid} name={slice.primary.anchor.uid}></a>
  )
}

const CookieLink = ({slice}) => {
  return (
    <Box typography="body1" pb={2}>
      You can change your cookie preferences any time by  <Link className="cky-banner-element" sx={{cursor: 'pointer'}}>clicking here</Link>. This will let you revisit the cookie consent banner and change your preferences or withdraw your consent right away. 
    </Box>
  )
}

const CookieTypes = ({slice}) => {
  return (
    <Box typography="body1" pb={1}>
      <div className="cky-audit-table-element"></div>
    </Box>
  )
}

const EmbeddedVideo = ({slice}) => {
  return (
    <Box>
      <div dangerouslySetInnerHTML={{ __html: slice.primary.video_html[0].text }} />
    </Box>
  )
}


const sliceList = {
  text_area: TextArea,
  two_column_header: Header2Column,
  two_column_with_large_quote: Column2Quote,
  columns__2_w__quote: Column2Quote,
  employee_section: Employees,
  banner__vertical: BannerVertical,
  article_quote: ArticleQuote,
  article_image: ArticleImageFull,
  article_image_half: ArticleImageHalf,
  header_tinted_image: HeaderTint,
  latest_articles_large: LatestLarge,
  page_anchor: Anchor,
  types_of_cookies: CookieTypes,
  cookie_link: CookieLink,
  embedded_video: EmbeddedVideo
}

const SliceZoneFade = (props) => {
  const slices = props.slices;
  return (
    <Box>
      {slices.map( (slice, i) => {
        let addVar = props.fadeStart ? props.fadeStart + i : i;
        let thisFade = i+addVar > props.fadeTime.length-1 ? props.fadeTime.length-1 : i+addVar;
        return (
          <Box key={'slice' + i} mb={(i === slices.length-1) || (slice.slice_type === 'page_anchor') ? 0 : props.spacing ? props.spacing : 8}>
          <Fade in={props.fadeTime[thisFade]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
            <Box>
              <SliceZone slices={[{...slice, category: props.category}]} components={sliceList} />
            </Box>
          </Fade>
          </Box>
        )
      })}
    </Box>
  )
}


export { sliceList, TextArea, Header2Column, Column2Quote, Employees, SliceZoneFade, ResponsiveImage, linkResolver, Anchor, ResponsiveImageClean  };